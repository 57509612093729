












































import Vue from "vue";
import gql from "graphql-tag";
import OnlineIndicator from "@/components/OnlineIndicator.vue";
import Headline from "@/components/Headline.vue";
import Head from "@/components/table/Head.vue";
import DefaultCell from "@/components/table/DefaultCell.vue";
import ImageThumb from "@/components/ImageThumb.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import LinkAction from "@/components/LinkAction.vue";
import PaginationLink from "@/components/table/PaginationLink.vue";
import ModelTabs from "@/components/ModelTabs.vue";
import draggable from "vuedraggable";

const limit = 10;

export default Vue.extend({
  data() {
    return {
      selectedModelID: undefined as number | undefined,
    };
  },
  apollo: {
    pdb_enginelinks: {
      query: gql`
        query pdb_enginelinks($modelID: bigint) {
          pdb_enginelinks(
            where: {
              trimlevel: { modeltype: { modell_id: { _eq: $modelID } } }
            }
          ) {
            enginetype {
              engine {
                name
              }
              gear {
                name
              }
              drive {
                name
              }
            }
            id
            wheelslinks_aggregate {
              aggregate {
                count
              }
            }
            trimlevel {
              name
              modeltype {
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          modelID: this.selectedModelID,
        };
      },
    },
  },
  methods: {
    setModel(modelID: number) {
      this.selectedModelID = modelID;
    },
    remove(enginetypeID: number) {
      if (window.confirm("Willst du wirklich löschen?")) {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($enginetypeID: bigint) {
                delete_pdb_pdb_enginelinks(
                  where: { id: { _eq: $enginetypeID } }
                ) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              enginetypeID: enginetypeID,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$apollo.queries.pdb_enginelinks.refetch();
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    edit(enginelinkID: number) {
      this.$router.push("/rims/assign/" + enginelinkID);
    },
  },
  created() {
    this.$apollo.queries.pdb_enginelinks.refetch();
  },
  components: {
    OnlineIndicator,
    Headline,
    Head,
    DefaultCell,
    ImageThumb,
    PrimaryButton,
    draggable,
    PaginationLink,
    LinkAction,
    ModelTabs,
  },
});
